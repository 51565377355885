body {
  background-color: #282c34 !important;
}

.App {
  text-align: center;
  background-color: #282c34;
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-link {
  color: #61dafb;
}

.profile-pic {
  display: block;
  width: 100%;
  height: auto;
}

.about-me {
  font-size: 1em;
}

.topic-container {
  width: 100%;
  padding: 1.5em 0em 1.5em;
  margin-bottom: 0;
  border-top: 1px solid white;
  scroll-margin-top: 3em;
}

.topic-container h1{
  margin-bottom: 1em;
}

.lang-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5em 0em 1em !important;
}

.lang-item {
  background-color: rgb(45, 105, 162);
  color: white;
  border-radius: 1em;
  width: fit-content;
  padding: 0em 1em 0.25em;
  margin: 0em 0.25em 0em;
  display: inline-block;
}

a.lang-item:hover {
  color: rgb(188, 188, 188);
}

a.lang-item:active {
  color: rgb(138, 138, 138);
}

.project-body {
  width: 100%;
  margin: auto;
}

.carousel-caption h3 {
  font-size: 1.5em;
}

@media only screen and (max-width: 767px) {
  
  .d-sm-none {
    display: none;
  }
  .d-sm-block {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .project-body {
    width: 75%;
  }

  .d-md-none {
    display: none;
  }
  .d-md-block {
    display: block;
  }
}

@media only screen and (max-width: 991px) {
  .w-md-100 {
    width: 100% !important;
  }  
}

@media only screen and (min-width: 992px) {
  .w-lg-75 {
    width: 75% !important;
  }  
}

@media only screen and (max-width: 992px) {
  .carousel-caption h3 {
    font-size: 1em;
  }
}

.skill-container {
  width: 100%;
  box-sizing: content-box;
  height: fit-content;
  background-color: #272727;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  border-top: 1px solid white;
}

.skill-container:nth-child(2n) {
  background-color: #202020;
}

.skill-container:last-child {
  border-bottom: 1px solid white;
}

.skill-container p {
  padding-bottom: 0;
  margin-bottom: 0;
}

.project-description {
  padding-bottom: 1em;
}

.experience-container {
  border-top: 1px solid white;
  padding-top: 1.5em;
}

.experience-line p {
  margin-bottom: 0.25em;
}

.experience-skill {
  margin-bottom: 0.25em;
}

.footer-links {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.carousel-image-container {
  display: flex;
  align-content: center;
  width: 75%;
  margin: auto;
  padding-bottom: 8em;
}

.header {
  width: 100%;
  text-align: center;
  align-items: center;
  align-content: center;
}

.carousel-image {
  width: 100%;
  margin: auto;
}

.footer {
  width: 100%;
  min-height: 4em;
  border-top: 1px solid white;
  padding: 1em;
}

.accordion-custom {
  background: #282c34;
  color: white;
}

.accordion-custom button {
  background: #222326;
  color: white;
}

.accordion-custom button::after {
  color: white;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
}

.accordion-button:not(.collapsed) {
  background: #222326 !important;
  color: white;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

/* Markdown styling */

.markdown-image-container {
  max-height: 256px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.markdown-image-container img {
  display: flex;
  max-height: 256px;
}

.markdown-image-container video {
  display: flex;
  max-height: 256px;
}